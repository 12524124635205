// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brandpad-tsx": () => import("./../src/pages/brandpad.tsx" /* webpackChunkName: "component---src-pages-brandpad-tsx" */),
  "component---src-pages-crowdworks-tsx": () => import("./../src/pages/crowdworks.tsx" /* webpackChunkName: "component---src-pages-crowdworks-tsx" */),
  "component---src-pages-futuregroup-tsx": () => import("./../src/pages/futuregroup.tsx" /* webpackChunkName: "component---src-pages-futuregroup-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-klaveness-tsx": () => import("./../src/pages/klaveness.tsx" /* webpackChunkName: "component---src-pages-klaveness-tsx" */),
  "component---src-pages-otovo-tsx": () => import("./../src/pages/otovo.tsx" /* webpackChunkName: "component---src-pages-otovo-tsx" */),
  "component---src-pages-teslactrl-tsx": () => import("./../src/pages/teslactrl.tsx" /* webpackChunkName: "component---src-pages-teslactrl-tsx" */),
  "component---src-pages-venduai-tsx": () => import("./../src/pages/venduai.tsx" /* webpackChunkName: "component---src-pages-venduai-tsx" */),
  "component---src-pages-xproject-tsx": () => import("./../src/pages/xproject.tsx" /* webpackChunkName: "component---src-pages-xproject-tsx" */)
}

